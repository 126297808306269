//Shapes.js
export const shapes = [
'Alabastra',
'Bell-Krater',
'Bottle',
'Calyx-Krater',
'Cup',
'Ekanides',
'Epichysis',
'Fish-plate',
'Fragment',
'Frr.',
'Guttus',
'Hydria',
'Kylix',
'Lebes Gamikos',
'Lebetes Gamikoi',
'Lekanides',
'Lekanis',
'Lekythoi',
'Lekythos',
'Lepaste',
'Lid',
'Lost Vase',
'Neck-amphora',
'Neck-amphorae',
'Oenochoai',
'Oenochoe',
'Olpe',
'Otle',
'Pelikai',
'Pelike',
'Phiale',
'Plate',
'Rhyton',
'Ring Askoi',
'Ring Askos',
'Section of Lid',
'Skyphoi',
'Skyphoid Pyxides',
'Skyphoid Pyxis',
'Skyphos',
'Spouted bottle',
'Squat Lekythoi',
'Squat lekythos',
'Stamnoid lebes',
'Stemless Cup',
'Stemmed Plate',
'Vacant',
'Volute-Krater'
]