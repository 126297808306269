import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="grid-footer-Author">&copy;2024 Trendall Research Centre</div>
    </footer>
  );
}

export default Footer;
