//Shapes.js
export const inventory = [
    '2641',
    '4955',
    '5068',
    '21183',
    '21403',
    '22596',
    '*183 Geneva, private coll.',
    '*184 Geneva, private coll.',
    '*186 Madrid 11445 (L. 433)',
    '*Paestum 5075',
    '129A Once Market',
    '133 San Antonio (Texas), Gilbert M. Denman Jr. coll. 276',
    '134 Paestum 20202, from Agropoli (Muoio, C. Vecchia, 1967)',
    '135 Naples 2873 (inv. 81847), from Paestum',
    '144 Paestum 3781-3, 3789, 3793, from Temple B in the Temenos',
    '146A Paestum 28872, from C. Andriuolo (1969)—sporadic',
    '146B Paestum 28847, from C. Andriuolo (1969)—sporadic',
    '147 Brussels A 813',
    '148 Paestum 48431, from the area of the Heraion',
    '149 Paestum 32128, from Tempa Rossa di Fonte (1964)',
    '150 Paestum 20161, from Agropoli (Muoio, C. Vecchia, 1967)',
    '151 Paestum 26631, from C. Gaudo (1972), T. 2',
    '152 Paestum 26633, from C. Gauda (1972), T. 2',
    '153 Paestum 26636, from C. Gauda (1972), T. 2',
    '154 Paestum 26635, from C. Gaudo (1972), T. 2',
    '155 Paestum 26638, from C. Gaudo (1972), T. 2',
    '156 Paestum 26632, from C. Gaudo (1972), T. 2',
    '157 Paestum 20210, from C. Licinella (1967), T. 10',
    '158 Paestum 4807, from C. Gaudo (1953), T. 2',
    '159 Paestum 21192, from C. Andriuolo (1969), T. 9',
    '160 Paestum, from C. Gaudo (1970), T. 1',
    '161 Paestum HU/33 (= NF 8), from area of Heraion (Loc. IV)',
    '162 Paestum 21135, from C. Andriuolo (1969), T. 1',
    '163 Paestum 21555, from C. Andriuolo (1969), T. 53',
    '164 Once Nocera, Fienga coll.',
    '165 From Eboli',
    '166 Madrid 11235 (L. 396)',
    '167 Naples, private coll. (2) 4-21-1',
    '168 Madrid 11278',
    '169 Paestum 26652, from C. Gaudo (1972), T. 3',
    '170 Dublin 1880-505',
    '171 Salerno, Soprintendenza, from Montesarchio, T. 1550',
    '172 Paestum 24294, from C. Andriuolo (1970), T. 147',
    '173 Paestum 20198, from Agropoli (Muoio, 1967), T. 11',
    '174 Rheinland, private coll.',
    '175 Salerno, Mus. Prov. Pc 1525, from Pontecagnano',
    '176 Vatican U 19 (inv. 17106)',
    '177 Madrid 11028 (L. 387)',
    '178 Once Leeuwarden, A. C. Beeling',
    '179 Madrid 11069 (L. 374)',
    '180 Pontecagnano, from T. 5173',
    '181 Paestum, from C. Laghetto (1955), T. 64',
    '182 New York, David Rockefeller coll.',
    '185 Paestum 20201, from Agropoli',
    '187 Naples 2878 (inv. 81879)',
    '188 Madrid 11455 (L. 435)',
    '189 Naples, private coll. (1) 107',
    '20336',
    '2549 (inv. 82062)',
    '2553 (inv. 82094)',
    '45 Paestum, from C. Licinella (1968)—sporadic, environs of T. 6-8',
    '70 Madrid, Garcia coll.',
    '87 Madrid 11232',
    '88 Naples 1777',
    '89 Madrid 11231',
    'Adolphseck 173 (on loan to Kassel, AV 541)',
    'Adolphseck, Schloss Fasanerie 171',
    'Agrigento 11431',
    'Agrigento R 205',
    'Area of Heraion',
    'Athens Market',
    'Athens Market, Acheloos Gallery',
    'Athens Market, Acheloos Gallery 442',
    'Athens Market, Acheloos Gallery 465',
    'Auxerre 9',
    'Avignon, Musee Calvet',
    'B.M. 1772.3-20.403 (old cat. 1580)',
    'B.M. 1917.12-10.1 (ex Hope 267)',
    'B.M. 1953.4-25.1',
    'B.M. 1957.2-12.6',
    'B.M. 1957.2-12-11',
    'B.M. 1977.5-22.36',
    'B.M. 67.5-8.1275',
    'B.M. 67.5-8.1276',
    'B.M. 67.5-8.1318',
    'B.M. F 129, from Nola (Blacas Tomb)',
    'B.M. F 130, from Nola (Blacas Tomb)',
    'B.M. F 140',
    'B.M. F 149, from S. Agata',
    'B.M. F 150',
    'B.M. F 152',
    'B.M. F 153',
    'B.M. F 154',
    'B.M. F 155',
    'B.M. F 188',
    'B.M. F 189, from Capua',
    'B.M. F 243',
    'B.M. F 253',
    'B.M. F 260 (1772.3-20.232)',
    'B.M. F 357',
    'B.M. F 360',
    'B.M. F 371',
    'B.M. F 393 (1772.3-20.228)',
    'B.M. F10',
    'B.M. F262',
    'B.M., from the wreck of the Colossus',
    'Baranello 203',
    'Barcelona',
    'Bari 3581, from Taranto',
    'Bari, Cotecchia coll. 60',
    'Basel',
    'Basel Market',
    'Basel Market, M u M',
    'Basel Market, M u M',
    'Basel Market, MuM',
    'Basel Market, MuM',
    'Basel Z 313',
    'Basel, Antikenmuseum',
    'Basel, Historisches Museum 1906.268',
    'Basel, private coll.',
    'Benevento',
    'Benevento 28189, from Montesarchio, T. 272',
    'Benevento 28205',
    'Benevento 28208',
    'Benevento 28223',
    'Benevento 28466',
    'Benevento 28497',
    'Benevento 348 (old no. 1503), from Moiano',
    'Benevento 348 xviii',
    'Benevento 348 XXVII',
    'Benevento 601 S',
    'Benevento 613S',
    'Benevento 614 S',
    'Benevento 615 S (348a)',
    'Benevento 616 S (348c)',
    'Benevento 624S (348 XXVI)',
    'Benevento, from Montesarchio, T. 1310',
    'Benevento, from Montesarchio, T. 1625',
    'Berkeley, U C L M A 8/446',
    'Berlin F 3025',
    'Berlin F 3026, from Calvi',
    'Berlin F 3032',
    'Berlin F 3033',
    'Berlin F 3052',
    'Berlin F 3053',
    'Berlin F 3058, from Nola',
    'Berlin F 3060',
    'Berlin F 3069',
    'Berlin F 3081',
    'Berlin F 3213',
    'Berlin F 3297 a',
    'Berlin F 3605',
    'Berlin F 3612',
    'Berlin F 4100',
    'Berlin F 4129',
    'Berlin F2960',
    'Berlin F3044',
    'Berlin F3049',
    'Berlin F3050',
    'Berlin inv. 4532',
    'Bern D 5, on loan from Dr. C. Clairmont',
    'Bloomington, I.U.A.M. 75.104',
    'Bochum S 1011',
    'Bochum S 1080',
    'Bochum, Ruhr-Universitat',
    'Bologna 436',
    'Bologna 437',
    'Bologna 490',
    'Bologna 491',
    'Bonn 3038',
    'Boston 12.423',
    'Boston 19.295, from Paestum',
    'Boston 19.299',
    'Boston 19.308',
    'Boston 19.309',
    'Boston 1986.263',
    'Boston 95.834',
    'Boston 95.835',
    'Boston 99.540',
    'Bourges 891.5.66',
    'Braunschweig A T 372',
    'Brisbane, University of Queensland 64.1',
    'Brussels R 261',
    'Brussels R 277',
    'Bucharest M.N.A. 74926 (ex Lens coll. 0427)',
    'Budapest 75.44A',
    'Buenos Aires, University—Museo Etnografico 44',
    'Buffalo, Society of Nat. Sciences C 12849',
    'Cambridge (Mass.), Harvard University, Peabody Museum',
    'Cambridge G R 7 . 1943 (ex M u s e u m Disneianum)',
    'Cambridge, Mr. R. Way',
    'Canberra 83.01',
    'Canberra, A.N.U.',
    'Catania 4327',
    'Catania, University, Arch. Inst. 9422',
    'Cefalu 2',
    'Cefalu 7',
    'Cefalù 8',
    'Chicago, N.H.M. 166575',
    'Christchurch (N.Z.), University of Canterbury 106/70',
    'Cleveland 85-50',
    'Collonges (Geneva), private coll.',
    'Cologne Market',
    'Cologne, Tollmann coll.',
    'Como C 128',
    'Compiegne 1026',
    'Compiegne 1069',
    'Copenhagen 8377',
    'Copenhagen 98 (B-S 269)',
    'Copenhagen A B c 985',
    'Copenhagen inv. Chr. VIII 290 (B.-S. 257B)',
    'Copenhagen inv. Chr. VIII 337 (= B.-S. 258)',
    'Copenhagen, N y Carlsberg H 53',
    'Copenhagen, N.M. 9183',
    'Cracow, once Czartoryski Museum 1461',
    'Detroit 24.156',
    'Dublin 1880-510',
    'Dublin, University College 1468',
    'Dublin, University College 147',
    'Dundee 67-6-6',
    'Dunedin E 23.8',
    'Dunedin E 25-2',
    'Dunedin E 41.1',
    'Dunedin E48.261',
    'Florence, private coll.',
    'Florence, private coll. 48',
    'Florence, Vagnonville 671',
    'Fogg Art Museum 25-30-55',
    'Frankfurt p 593',
    'Freiburg Market',
    'Freiburg Market, Gunter Puhze',
    'Gela (from the Acropolis)',
    'Gela 298',
    'Gela 8255-6',
    'Gela 9183 (fr.) from Monte Saraceno',
    'Gela, Iacona coll.',
    'Geneva',
    'Geneva 23472',
    'Geneva H R 29',
    'Geneva I 435',
    'Geneva I 462',
    'Geneva I 697',
    'Geneva I 718',
    'Geneva I 93',
    'Geneva M F 244',
    'Geneva Market',
    'Geneva Market, Koutoulakis',
    'Geneva Market, Koutoulakis (257)',
    'Geneva Market, Koutoulakis 256',
    'Geneva, Dr. J. Chamay',
    'Geneva, private coll.',
    'Genoa',
    'Genoa 1225',
    'Graham Geddes coll. P 4:0',
    'Graham Geddes coll. P 5:2',
    'Hamburg, private coll.',
    'Hamburg, Schwarzkopf coll.',
    'Hamburg, Termer coll.',
    'Hamburg, Termer coll.',
    'Hanover R 1906.160',
    'Hanover, private coll.',
    'Haverford (Pa.), Haverford College 5',
    'Haverford, Haverford College 6',
    'Heligoland, Kropatschek coll. 114',
    'Hillsborough, Mrs. R. A. Hearst coll. 23, ex San Simeon 5434',
    'Japan, private coll.',
    'Japanese private coll.',
    'Jena 257 (431)',
    'Joliet (111.), Harlan J. Berk',
    'Karlsruhe B 91 (W. 375)',
    'Karlsruhe B 92 (W. 351)',
    'Kassel T 821, ex Basel and London Markets',
    'Kassel T646',
    'Kiel B 555',
    'Laon 37.1074',
    'Lausanne, University',
    'Lecce 741, from Rugge',
    'Leiden G N V 138',
    'Leiden K 1957/2. 1',
    'Leiden K 1959/2',
    'Leiden K 1960/4',
    'Leipzig T 4740',
    'Leningrad 1660 (St. 1777)',
    'Leningrad 376 (St. 1793)',
    'Leningrad 4746',
    'Leningrad inv. 2916',
    'Leningrad inv. 3044',
    'Leningrad inv. 360',
    'Limoges 7910',
    'Lipari 11806',
    'Lipari 11807',
    'Lipari 6814',
    'Lipari 927',
    'Lipari 9558',
    'Lipari 9604',
    'Lipari 9671',
    'Lipari inv. 168 F (from T. 24)',
    'Lisbon, Duke of Palmella',
    'Liverpool M 10711',
    'Locri 1969/150 A - C, from Locri',
    'London Market',
    'London Market, B.A. Seaby Ltd.',
    'London Market, Charles Ede Ltd.',
    'London Market, Charles Ede Ltd., ex Sotheby',
    'London Market, Charles Ede Ltd., ex Sotheby, Sale Cat. 8-9 Dec. 1986, no. 191',
    'London Market, Coins and Antiquities AN 703',
    'London Market, McAlpine GR 466',
    'London Market, Sotheby',
    'London Market, Sotheby, Sale Cat. 13 July 1987, no. 284',
    'Los Angeles 50.8.30 (ex Hope 273)',
    'Los Angeles 50.8.40 (ex Hope 271)',
    'Los Angeles Market',
    'Los Angeles Market, Barakat Gallery',
    'Los Angeles Market, Barakat Gallery PF 292',
    'Los Angeles, Dechter coll.',
    'Los Angeles, Sidney Port coll. (ex Summa Galleries, inv. 82)',
    'Lost',
    'Lost, once Eboli',
    'Lost, once Naples, Jamineau',
    'Lost; once Naples, A. F. Gori',
    'Lost—Tischbein I 26',
    'Louvre CA 2270',
    'Louvre E D 718',
    'Louvre G 517',
    'Louvre K 234',
    'Louvre K 236',
    'Louvre K 237',
    'Louvre K 238',
    'Louvre K 24',
    'Louvre K 240',
    'Louvre K 241',
    'Louvre K 242',
    'Louvre K 243',
    'Louvre K 244',
    'Louvre K 247',
    'Louvre K 248',
    'Louvre K 249',
    'Louvre K 254',
    'Louvre K 258',
    'Louvre K 264',
    'Louvre K 265',
    'Louvre K 265 bis',
    'Louvre K 267',
    'Louvre K 286',
    'Louvre K 287',
    'Louvre K 3 0 1',
    'Louvre K 302',
    'Louvre K 303',
    'Louvre K 308',
    'Louvre K 315',
    'Louvre K 325',
    'Louvre K 344',
    'Louvre K 350',
    'Louvre K 367',
    'Louvre K 368',
    'Louvre K 570',
    'Louvre K235',
    'Louvre K250',
    'Louvre K257',
    'Louvre K363',
    'Louvre K364',
    'Louvre K573',
    'Louvre N 2933 (lid) + 2971 (bowl)',
    'Louvre N 3148',
    'Louvre N 3157, from S. Agata',
    'Louvre S 1592 (ED 861)',
    'Lugano, private coll. 194',
    'Madrid',
    'Madrid 11019',
    'Madrid 11026 (L. 388)',
    'Madrid 11033 (L. 381)',
    'Madrid 11037 (L. 383)',
    'Madrid 11054',
    'Madrid 11058',
    'Madrid 11059',
    'Madrid 11060',
    'Madrid 11062',
    'Madrid 11067',
    'Madrid 11094',
    'Madrid 11136',
    'Madrid 11137',
    'Madrid 11138 (L. 422)',
    'Madrid 11139',
    'Madrid 11142 (L. 421)',
    'Madrid 11145',
    'Madrid 11146 (L. 425)',
    'Madrid 11147 (L. 427)',
    'Madrid 11148',
    'Madrid 11149',
    'Madrid 11150',
    'Madrid 11151 (L. 428)',
    'Madrid 11152 (L. 429)',
    'Madrid 11155',
    'Madrid 11205 (L. 489)',
    'Madrid 11206',
    'Madrid 11222',
    'Madrid 11226 (L. 394)',
    'Madrid 11227 (L. 393)',
    'Madrid 11228 (L. 403)',
    'Madrid 11230',
    'Madrid 11236',
    'Madrid 11237 (L. 405)',
    'Madrid 11238',
    'Madrid 11240 (L. 403)',
    'Madrid 11241 (L. 404)',
    'Madrid 11242',
    'Madrid 11243 (L. 414)',
    'Madrid 11244 (L. 409)',
    'Madrid 11245',
    'Madrid 11246',
    'Madrid 11249 (L. 411)',
    'Madrid 11250 (L. 412)',
    'Madrid 11251 (L. 413)',
    'Madrid 11259',
    'Madrid 11261',
    'Madrid 11279 (L. 505)',
    'Madrid 11280 (L. 509)',
    'Madrid 11282 (L. 508)',
    'Madrid 11283',
    'Madrid 11285 (L. 506)',
    'Madrid 11286 (L. 513)',
    'Madrid 11293',
    'Madrid 11294 (L. 512)',
    'Madrid 11307',
    'Madrid 11308',
    'Madrid 11313',
    'Madrid 11314 (L. 527)',
    'Madrid 11315 (L. 520)',
    'Madrid 11318',
    'Madrid 11325',
    'Madrid 11327 (L. 528)',
    'Madrid 11335 (L. 522; lid only)',
    'Madrid 11367',
    'Madrid 11368 (L. 362)',
    'Madrid 11369',
    'Madrid 11370',
    'Madrid 11371',
    'Madrid 11372',
    'Madrid 11373',
    'Madrid 11374 (L. 366)',
    'Madrid 11387 (L. 495)',
    'Madrid 11388',
    'Madrid 11391 (L. 492)',
    'Madrid 11395',
    'Madrid 11399 (L. 496)',
    'Madrid 11441 (L. 437)',
    'Madrid 11442 (L. 434)',
    'Madrid 11444 (L. 436)',
    'Madrid 11449',
    'Madrid 11451 (L.445)',
    'Madrid 11453',
    'Madrid 11454 (L. 446)',
    'Madrid 11456 (L. 442)',
    'Madrid 11457 (?)',
    'Madrid 11458',
    'Madrid 11459',
    'Madrid 11462',
    'Madrid 11484 (L. 479)',
    'Madrid 11488 (L. 349)',
    'Madrid 11489',
    'Madrid 11491 (L. 488)',
    'Madrid 11493 (L. 476)',
    'Madrid 11494 (L. 478)',
    'Madrid 11496 (L. 475)',
    'Madrid 11497 (L. 480)',
    'Madrid 11498 (L. 483)',
    'Madrid 11499 (L. 481)',
    'Madrid 11515 (L. 450)',
    'Madrid 11518 (L. 451)',
    'Madrid 11519',
    'Madrid 11521 (L. 449)',
    'Madrid 11522 (L. 470)',
    'Madrid 11523',
    'Madrid 11524',
    'Madrid 11526',
    'Madrid 11529',
    'Madrid 11533 (L. 469)',
    'Madrid 11534',
    'Madrid 11535 (L. 463)',
    'Madrid 11539',
    'Madrid 11540 (L. 471)',
    'Madrid 11542 (L. 460)',
    'Madrid 11543',
    'Madrid 11550 (L. 494)',
    'Madrid 11564',
    'Madrid 11568',
    'Madrid 11583',
    'Madrid 11607 (L. 350)',
    'Madrid 32653 (ex Prado 120)',
    'Madrid 32661 (Prado 150)',
    'Madrid, no. lost',
    'Mainz O. 29212',
    'Mainz, R G Z M O',
    'Mainz, RGZM 0.12946',
    'Mainz, RGZM 0.13000',
    'Malibu',
    'Malibu 80 AE 153',
    'Malibu 81 A E 120',
    'Malibu 81 A E 78',
    'Manchester IV E 5',
    'Mannheim C g 144',
    'Mannheim C g 3',
    'Mannheim, ex Hamburg, Termer Coll.',
    'Matera 156622',
    'Meer, private coll. F 17',
    'Melbourne D 391/1980',
    'Melbourne University V53',
    'Melbourne, G. Geddes coll.',
    'Melbourne, Geddes coll.',
    'Melbourne, Geddes coll.',
    'Melbourne, Geddes coll. P 2:1',
    'Melbourne, Geddes coll. P 3:1',
    'Melbourne, Geddes coll. P 5:1',
    'Melbourne, Geddes coll. P.6:0',
    'Melbourne, Geddes coll. P2:2',
    'Melbourne, Graham Geddes coll. C 1: 2',
    'Melbourne, Graham Geddes coll. C 1:0',
    'Melbourne, La Trobe University 85.01',
    'Milan 261',
    'Milan, Catholic University P. 33',
    'Mnichovo Hradiste, Casde 1613',
    'Mnichovo Hradiste, Castle 1625',
    'Monopoli, Meo-Evoli coll. 212',
    'Montpellier, Musee Fabre 39.1678',
    'Moscow 487',
    'Moscow 735',
    'Moscow, Pushkin Museum 185',
    'Munich 3295 (seemingly destroyed in World War II)',
    'Munich, Dr. Kroll',
    'Naples',
    'Naples',
    'Naples 124283',
    'Naples 124287',
    'Naples 124288',
    'Naples 128018',
    'Naples 128035, from Cumae',
    'Naples 134285',
    'Naples 146775',
    'Naples 147324',
    'Naples 1772 (inv. 82243)',
    'Naples 1773 (inv. 82601)',
    'Naples 1774 (inv. 81659)',
    'Naples 1778 (inv. 82127)',
    'Naples 1779 (inv. 81731)',
    'Naples 1784',
    'Naples 1785 (inv. 81677)',
    'Naples 1786 (inv. 82178)',
    'Naples 1787 (inv. 81684), from Paestum',
    'Naples 1788',
    'Naples 1791 (inv. 82916)',
    'Naples 1792 (inv. 81676)',
    'Naples 1793',
    'Naples 1794 (inv. 81681)',
    'Naples 181117',
    'Naples 1853, from Paestum',
    'Naples 185580',
    'Naples 185587',
    'Naples 186375',
    'Naples 1876 (inv. 82614)',
    'Naples 1888',
    'Naples 1890 (inv. 81721)',
    'Naples 1904 (inv. 82759), from Paestum',
    'Naples 1942 (inv. 82571)',
    'Naples 1960 (inv. 82173)',
    'Naples 1965 (inv. 81788)',
    'Naples 2074 (inv. 81437)',
    'Naples 2097 (inv. 81465)',
    'Naples 2233 (inv. 81787), from Paestum',
    'Naples 2245',
    'Naples 2267',
    'Naples 2389',
    'Naples 2550 (inv. 82095), from Paestum',
    'Naples 2583',
    'Naples 2585 (inv. 82084)',
    'Naples 2589',
    'Naples 2592 (inv. 82530)',
    'Naples 2681',
    'Naples 2693 (inv. 82066)',
    'Naples 2846 (inv. 81417), from S. Agata',
    'Naples 3025 (inv. 81914)',
    'Naples 3126',
    'Naples 3226',
    'Naples 3248 (inv. 82126)',
    'Naples 3412',
    'Naples 3426',
    'Naples 3427 (inv. 82832)',
    'Naples 3468',
    'Naples 723 (inv. 81892)',
    'Naples 734',
    'Naples 736 (inv. 82737)',
    'Naples 739 (inv. 82807)',
    'Naples 781 (inv. 82603)',
    'Naples 786 (inv. 82760)',
    'Naples 803 (inv. 82745)',
    'Naples 816 (81896), from Paestum',
    'Naples 818 (inv. 82617)',
    'Naples 824 (inv. 82613)',
    'Naples 828 (inv. 81889)',
    'Naples 905 (inv. 82620)',
    'Naples 946 (inv. 82652)',
    'Naples 969 (inv. 82887)',
    'Naples RC 265 (inv. 86405 = Heyd. 47), from Cumae',
    'Naples S N 76 D G',
    'Naples Stg. 432',
    'Naples Stg. 682',
    'Naples VI 1401, from Paestum',
    'Naples, private coll.',
    'Naples, private coll. (1) 508',
    'Naples, private coll. (2) 1-1-13',
    'Naples, private coll. (2) 4-1-1',
    'Naples, private coll. (2) 4-1-2',
    'Naples, private coll. (2) 4-12-2',
    'Naples, private coll. (2) 4-1-5',
    'Naples, private coll. (2) 4-15-1',
    'Naples, private coll. (2) 4-15-2',
    'Naples, private coll. (2) 4-1-6',
    'Naples, private coll. (2) 4-16-1',
    'Naples, private coll. (2) 4-16-2',
    'Naples, private coll. (2) 4-1-7',
    'Naples, private coll. (2) 4-1-8',
    'Naples, private coll. (2) 4-5-1',
    'Naples, private coll. (2) 4-5-2',
    'Naples, private coll. (2) 4-6-1',
    'Naples, private coll. (2) 4-6-3',
    'Naples, private coll. (2) 4-9-1',
    'Naples, private coll. (2) 4-9-2',
    'Naples, private coll. (2) 4-9-4',
    'Naples, private coll. (2), 4-6-2',
    'Narbonne 3684 (26)',
    'New York 1976.11.5',
    'New York 1985.74',
    'New York 62.11.3',
    'New York 65.11.18',
    'New York Market, Andre Emmerich Gallery G R 247',
    'New York Market, Harmer Rooke Galleries',
    'New York Market, Royal-Athena Galleries',
    'New York Market, Royal-Athena Galleries H F K 06',
    'New York Market, Royal-Athena Galleries H F N 06',
    'New York Market, Royal-Athena Galleries H F N 08',
    'New York, private coll, X 94',
    'New York, private coll, X99; once Nostell Priory (C. 36)',
    'New York, private coll. (V. Papagapitou)',
    'New York, private coll. x85; once Nostell Priory 28',
    'New York, Royal-Athena Galleries',
    'Nocera, Fienga 595',
    'North German private coll.',
    'Nostell Priory 41',
    'Not specified',
    'Not specified',
    'Odessa 23092',
    'Okayama 77',
    'Okayama, Kurashiki Museum 79',
    'Okayama, Kurashiki Ninagawa Museum 79',
    'Once Agrigento',
    'Once Agrigento, Giudice coll. 193',
    'Once Ascona Market, Galleria Serodine',
    'Once Ascona, Galleria Serodine, List 1984, no. 57',
    'Once Athens Market, Acheloos Gallery 454',
    'Once Athens Market, Acheloos Gallery 473',
    'Once Basel Market, Kunsthaus zum Gellert, Sale Cat. no. 24, 26-28 Nov. 1981, no. 2282',
    'Once Basel Market, M u M',
    'Once Basel Market, MuM',
    'Once Berlin Market, Bassenge, Auktion 19, 16-20 May 1972',
    'Once Berlin, Dr. Lederer',
    'Once Bern Market, Elsa Bloch-Diener',
    'Once Deepdene, Hope 264',
    'Once Deepdene, Hope 276',
    'Once Deepdene, Hope 277',
    'Once Deepdene, Hope coll. 263',
    'Once Deepdene, Hope coll. 281',
    'Once Frankfurt Market',
    'Once Freiburg Market, G. Puhze',
    'Once Freiburg Market, Günter Puhze, Kunst der Antike V, 1983, no. 221 (ill.)',
    'Once Freiburg Market, Puhze',
    'Once Geneva Market, Koutoulakis',
    'Once Geneva Market, Koutoulakis-Zimmermann',
    'Once Gy',
    'Once Gy (Geneva), Dr. Lauffenburger',
    'Once Hasselmann coll.',
    'Once Karlsruhe, Vogell coll. 549',
    'Once London Market, Charles Ede Ltd.',
    'Once London Market, Charles Ede Ltd. (2197); ex Sotheby, Sale Cat. 11 Dec. 1984, no. 578,1; GPSIXll (1987), no. 1 (ill.)',
    'Once London Market, Christie\'s',
    'Once London Market, Christie\'s',
    'Once London Market, Christie\'s Sale Cat.',
    'Once London Market, Christie\'s, Sale Cat. 17-18 Nov. 1977, no. 307, ill. on pi. 28',
    'Once London Market, Christie\'s, South Kensington, Sale Cat. 18 May 1983, no. 121',
    'Once London Market, Coins and Antiquities Ltd., Cat. 56, no. A N 727 (ill. on pi. 3)',
    'Once London Market, Sotheby',
    'Once London Market, Sotheby, Sale Cat. 13 July 1976, no. 444, ill. on p. 178',
    'Once London Market, Sotheby, Sale Cat. 8-9 Dec. 1986, no. 179, ex New York Market, Royal-Athena Galleries HFK 03',
    'Once Los Angeles Market, Summa Galleries, inv. 247',
    'Once Los Angeles Market, Superior Stamp and Coin Co.',
    'Once Lucerne Market, Ars Antiqua',
    'Once Naples, Pacileo',
    'Once New York Market, Atlantis Gallery',
    'Once New York Market, Royal-Athena Galleries',
    'Once New York Market, Royal-Athena Galleries SLP 12',
    'Once New York Market, Sotheby Parke Bernet, Sale Cat. 13 May 1978, no. 53 (ill. on p. 13)',
    'Once New York Market, Sotheby Parke Bernet, Sale Cat. 16 May 1980, no. 187 (ill.)',
    'Once Nocera, Fienga coll. (inv. De F. 582)',
    'Once Nocera, Fienga coll. 554',
    'Once Nocera, Fienga coll. 568',
    'Once Nostell Priory, Sale Cat. no. 31 c, pi. 11,5',
    'Once Paris Market, Hotel Nouveau Drouot',
    'Once Paris Market, Samarcande',
    'Once Ravello, Tallon-Lacaita coll., then Neuilly-sur-Seine, Ellen Lubszynski.',
    'Once Rome Market',
    'Once Stuttgart Market, Kricheldorf',
    'Once Swiss Market, Hecht',
    'Once Treben, von Leesen 135',
    'Once Zurich Market, 2873',
    'Once Zurich Market, Arete',
    'Once Zurich Market, Arete',
    'Once Zurich Market, Arete 2252',
    'Once Zurich Market, Arete inv. 2866',
    'Once Zurich Market, Arete inv. 2867',
    'Once Zurich Market, Arete inv. 2868',
    'Once Zurich Market, Bukowski,4 Sale Cat. 8 Dec. 1983, no. 134 (ill.)',
    'Once Zurich Market, Galerie Fortuna',
    'Once Zurich, Ruesch coll.',
    'Oxford 1928.12 (ex H o p e 275)',
    'Oxford 1942.293',
    'Oxford 1945. 43',
    'Oxford 1945.68',
    'Oxford 1945.79',
    'Padula',
    'Padula, from Tepedino',
    'Padula, from Valle Pupina, T. 16',
    'Padula, Museo Lucano',
    'Padula, Valle Pupina',
    'Paestum',
    'Paestum',
    'Paestum (fragment), from the Foce del Sele',
    'Paestum (frr.)',
    'Paestum (on loan to the University of Salerno)',
    'Paestum (once Salerno, Soprintendenza)',
    'Paestum 1237',
    'Paestum 1244, from Spinazzo, T. 3',
    'Paestum 1247, from Spinazzo',
    'Paestum 1248, from Spinazzo',
    'Paestum 1252',
    'Paestum 1253',
    'Paestum 1254',
    'Paestum 1273',
    'Paestum 1274',
    'Paestum 1275',
    'Paestum 1276',
    'Paestum 1422',
    'Paestum 1423',
    'Paestum 1736, from Gromola (Stregara)',
    'Paestum 1741, from area N. of the Porta Aurea',
    'Paestum 1748, from area N. of Porta Aurea',
    'Paestum 1749',
    'Paestum 1765',
    'Paestum 1769',
    'Paestum 1770',
    'Paestum 20137',
    'Paestum 20139',
    'Paestum 20142',
    'Paestum 20144',
    'Paestum 20188',
    'Paestum 20197',
    'Paestum 20199',
    'Paestum 20211, from C. Licinella (1967)',
    'Paestum 20212',
    'Paestum 20213',
    'Paestum 20293',
    'Paestum 20294',
    'Paestum 20295',
    'Paestum 20296',
    'Paestum 20298, from C. Licinella (1967), T. 13',
    'Paestum 20301',
    'Paestum 20302',
    'Paestum 20303',
    'Paestum 20308',
    'Paestum 20310',
    'Paestum 20314',
    'Paestum 20315',
    'Paestum 20316',
    'Paestum 20317',
    'Paestum 20324',
    'Paestum 20337, from C. Licinella (1967), T. 6',
    'Paestum 20338',
    'Paestum 20341',
    'Paestum 20344',
    'Paestum 20345',
    'Paestum 20347',
    'Paestum 20348',
    'Paestum 20350',
    'Paestum 20351',
    'Paestum 20368, from C. Licinella (1967), T. 30',
    'Paestum 20369',
    'Paestum 20370, from C. Licinella (1967)',
    'Paestum 20371',
    'Paestum 20386, from C. Licinella (1967), T. 31',
    'Paestum 21136',
    'Paestum 21137',
    'Paestum 21138',
    'Paestum 21141, from C. Andriuolo (1969), T. 1',
    'Paestum 21143, from C. Andriuolo (1969), T. 1',
    'Paestum 21150',
    'Paestum 21151',
    'Paestum 21152',
    'Paestum 21154',
    'Paestum 21159',
    'Paestum 21173',
    'Paestum 21180',
    'Paestum 21181',
    'Paestum 21184',
    'Paestum 21185',
    'Paestum 21191, from C. Andriuolo (1969), T. 9',
    'Paestum 21193',
    'Paestum 21198',
    'Paestum 21206',
    'Paestum 21207',
    'Paestum 21208',
    'Paestum 21218',
    'Paestum 21232, from C. Andriuolo (1969), T. 14',
    'Paestum 21233',
    'Paestum 21234',
    'Paestum 21235',
    'Paestum 21259',
    'Paestum 21306, from C. Andriuolo (1969), T. 20',
    'Paestum 21369',
    'Paestum 21370, from C. Andriuolo (1969), T. 24',
    'Paestum 21386',
    'Paestum 21387',
    'Paestum 21388',
    'Paestum 21389',
    'Paestum 21390',
    'Paestum 21401',
    'Paestum 21402',
    'Paestum 21404',
    'Paestum 21414',
    'Paestum 21415',
    'Paestum 21416',
    'Paestum 21423',
    'Paestum 21424',
    'Paestum 21427',
    'Paestum 21428',
    'Paestum 21429 (1969), T. 32',
    'Paestum 21441, from C. Andriuolo (1969), T. 38',
    'Paestum 21449',
    'Paestum 21452',
    'Paestum 21453',
    'Paestum 21456, from C. Andriuolo (1969)',
    'Paestum 21460',
    'Paestum 21466',
    'Paestum 21470',
    'Paestum 21471',
    'Paestum 21472',
    'Paestum 21476',
    'Paestum 21480',
    'Paestum 21481',
    'Paestum 21483',
    'Paestum 21485',
    'Paestum 21487, from C. Andriuolo (1969), T. 45',
    'Paestum 21490',
    'Paestum 21495',
    'Paestum 21503',
    'Paestum 21504',
    'Paestum 21514',
    'Paestum 21516',
    'Paestum 21517 (1969), T. 47',
    'Paestum 21518, from C. Andriuolo (1969), T. 47',
    'Paestum 21526',
    'Paestum 21527',
    'Paestum 21528',
    'Paestum 21532, from C. Andriuolo (1969), T. 49',
    'Paestum 21535, from C. Andriuolo (1969), T. 50',
    'Paestum 21539',
    'Paestum 21548',
    'Paestum 21556',
    'Paestum 21557',
    'Paestum 21560',
    'Paestum 21568',
    'Paestum 21570',
    'Paestum 21575',
    'Paestum 21576',
    'Paestum 21577',
    'Paestum 21581',
    'Paestum 21582',
    'Paestum 21585, from C. Andriuolo (1969)',
    'Paestum 21586, from C. Andriuolo (1969), T. 56',
    'Paestum 21591',
    'Paestum 21595',
    'Paestum 21600',
    'Paestum 21622, from C. Andriuolo (1969), T. 61',
    'Paestum 21623 (1969), T. 61',
    'Paestum 21624 (1969), T. 61',
    'Paestum 21625',
    'Paestum 21626',
    'Paestum 21629',
    'Paestum 21631',
    'Paestum 21632',
    'Paestum 21678',
    'Paestum 21681',
    'Paestum 21692',
    'Paestum 21694',
    'Paestum 21696',
    'Paestum 21702',
    'Paestum 21703',
    'Paestum 21704',
    'Paestum 21973, from C. Andriuolo (1969), T. 178',
    'Paestum 21988',
    'Paestum 22068',
    'Paestum 22120',
    'Paestum 22174',
    'Paestum 22256',
    'Paestum 22257, from C. Andriuolo (1969), T. 84',
    'Paestum 22258',
    'Paestum 22267, from C. Andriuolo (1969), T. 86',
    'Paestum 22268',
    'Paestum 22270, from C. Andriuolo (1969), T. 86',
    'Paestum 22271',
    'Paestum 22294',
    'Paestum 22306',
    'Paestum 22308',
    'Paestum 22309',
    'Paestum 22314',
    'Paestum 22316-7',
    'Paestum 22325',
    'Paestum 22340',
    'Paestum 22346',
    'Paestum 22347',
    'Paestum 22348',
    'Paestum 22350',
    'Paestum 22441',
    'Paestum 22443, from C. Andriuolo (1969), T. 102',
    'Paestum 22449, from C. Andriuolo (1969), T. 102',
    'Paestum 22572',
    'Paestum 22574',
    'Paestum 22586',
    'Paestum 22593',
    'Paestum 22609',
    'Paestum 22641',
    'Paestum 22660',
    'Paestum 22661',
    'Paestum 22735',
    'Paestum 22756, from C. Licinella',
    'Paestum 22780',
    'Paestum 22781, from C. Licinella (1968), T. 40',
    'Paestum 22782',
    'Paestum 22870',
    'Paestum 22871',
    'Paestum 22872',
    'Paestum 22906',
    'Paestum 22949',
    'Paestum 22950',
    'Paestum 22991, from C. Licinella (1969), sporadic',
    'Paestum 23054',
    'Paestum 23098',
    'Paestum 23099',
    'Paestum 24261',
    'Paestum 24320',
    'Paestum 24322',
    'Paestum 24323, from C. Andriuolo (1970)—sporadic',
    'Paestum 24584, from C. Andriuolo (1971), T. 15',
    'Paestum 24602, from C. Andriuolo (1971), T. 18',
    'Paestum 24603, from C. Andriuolo (1971), T. 18',
    'Paestum 24604',
    'Paestum 24605',
    'Paestum 24606',
    'Paestum 24607',
    'Paestum 24608',
    'Paestum 24609',
    'Paestum 24611',
    'Paestum 24612',
    'Paestum 24613',
    'Paestum 24614',
    'Paestum 24615',
    'Paestum 24616',
    'Paestum 24617',
    'Paestum 24646, from C. Andriuolo (1971), T. 1',
    'Paestum 24647, from C. Andriuolo (1971), T. 1',
    'Paestum 24658',
    'Paestum 24670',
    'Paestum 24671',
    'Paestum 24672, from C. Andriuolo (1971), T. 4',
    'Paestum 24673',
    'Paestum 24742, from C. Andriuolo (1971), T. 8',
    'Paestum 24853',
    'Paestum 24984, from C. Andriuolo',
    'Paestum 24985, from C. Andriuolo',
    'Paestum 24986',
    'Paestum 24987',
    'Paestum 25009',
    'Paestum 25010',
    'Paestum 25011, from C. Andriuolo (1971)',
    'Paestum 25012, from C. Andriuolo (1971)',
    'Paestum 25053',
    'Paestum 26605',
    'Paestum 26607',
    'Paestum 26637',
    'Paestum 26651, from C. Gaudo (1970), T. 3',
    'Paestum 26654',
    'Paestum 26655',
    'Paestum 26682',
    'Paestum 26713',
    'Paestum 26796',
    'Paestum 26963',
    'Paestum 26989',
    'Paestum 27004',
    'Paestum 27027',
    'Paestum 27028',
    'Paestum 27029',
    'Paestum 27088',
    'Paestum 27091',
    'Paestum 27092',
    'Paestum 28901, from C. Andriuolo (1969), sporadic',
    'Paestum 28902',
    'Paestum 28903',
    'Paestum 30842, from C. Licinella (1969), T. 65',
    'Paestum 31669',
    'Paestum 31700',
    'Paestum 31712',
    'Paestum 31713',
    'Paestum 31714, from C. Vannullo (1975)',
    'Paestum 31726',
    'Paestum 31727, from C. Vannullo (1975), T. 3',
    'Paestum 31728',
    'Paestum 31755, from C. Vannullo (1975), T. 5',
    'Paestum 31756',
    'Paestum 31774',
    'Paestum 31775',
    'Paestum 31863, from Gromola (Strecara—1960), T. 1',
    'Paestum 31868',
    'Paestum 31889',
    'Paestum 31890',
    'Paestum 31891',
    'Paestum 31897, from Roccadaspide, Fondo Castagno',
    'Paestum 31898',
    'Paestum 31906',
    'Paestum 31914',
    'Paestum 31915',
    'Paestum 31916',
    'Paestum 31917, Roccadaspide (Fondo Castagno) 1963, T. 4',
    'Paestum 31918',
    'Paestum 31972',
    'Paestum 31975, from Altavilla (1953), T. 1',
    'Paestum 31988',
    'Paestum 31989',
    'Paestum 31991',
    'Paestum 31992',
    'Paestum 31997',
    'Paestum 31998',
    'Paestum 31999',
    'Paestum 32000',
    'Paestum 32010',
    'Paestum 32011',
    'Paestum 32013',
    'Paestum 32015',
    'Paestum 32029',
    'Paestum 32030',
    'Paestum 32036, from C. Spina (1963)',
    'Paestum 32038',
    'Paestum 32048',
    'Paestum 32050, from C. Spina (1963), T. 9',
    'Paestum 32058, from C. Spina (1963)',
    'Paestum 32062',
    'Paestum 32067',
    'Paestum 32068',
    'Paestum 32113, from Tempa Rossa di Fonte (1964), T. 2',
    'Paestum 32115',
    'Paestum 32129',
    'Paestum 32130, from Tempa Rossa di Fonte (1964), T. 3',
    'Paestum 32218',
    'Paestum 32222',
    'Paestum 32243, from Spinazzo (1973), T. 9',
    'Paestum 32244',
    'Paestum 32245',
    'Paestum 32247, from C. Gaudo (1973)',
    'Paestum 32250',
    'Paestum 32252',
    'Paestum 32255',
    'Paestum 32284, from same site',
    'Paestum 32285, from Spinazzo (1973), T. 18',
    'Paestum 32308',
    'Paestum 32321',
    'Paestum 32322',
    'Paestum 32323',
    'Paestum 32382',
    'Paestum 32392',
    'Paestum 32394',
    'Paestum 32851',
    'Paestum 32852, Tempa del Prete (1974), T. 5',
    'Paestum 32861',
    'Paestum 32863',
    'Paestum 32865',
    'Paestum 32866',
    'Paestum 32893',
    'Paestum 34860',
    'Paestum 3489 BA',
    'Paestum 3551, BA',
    'Paestum 3907 BA',
    'Paestum 3908 (= NF 9)',
    'Paestum 39103',
    'Paestum 39104, from C. Gaudo',
    'Paestum 39126',
    'Paestum 40860, from S. Venera (1976), T. 58',
    'Paestum 40897, from S. Venera (1976), T. 59',
    'Paestum 40898',
    'Paestum 40961',
    'Paestum 40983',
    'Paestum 40986',
    'Paestum 40987',
    'Paestum 41 II',
    'Paestum 41062',
    'Paestum 41063',
    'Paestum 41064, from S. Venera (1976), T. 81',
    'Paestum 41125',
    'Paestum 41140',
    'Paestum 41156',
    'Paestum 41158, from Santa Venera (1976), T. 107',
    'Paestum 41159',
    'Paestum 41473, from Albanella (1979), T. 7',
    'Paestum 4794, from C. Gaudo (1957), T. 2',
    'Paestum 4802',
    'Paestum 4831',
    'Paestum 4832',
    'Paestum 4833',
    'Paestum 4834',
    'Paestum 4835',
    'Paestum 4836',
    'Paestum 48455, from C. Arcioni (1977), T. 271',
    'Paestum 48456',
    'Paestum 48460',
    'Paestum 48461',
    'Paestum 48462',
    'Paestum 4890',
    'Paestum 4891, from C. G a u d o (1957), T. 6',
    'Paestum 4892',
    'Paestum 4893',
    'Paestum 4913',
    'Paestum 4915',
    'Paestum 4921',
    'Paestum 4923',
    'Paestum 4924',
    'Paestum 4925',
    'Paestum 4926',
    'Paestum 4930',
    'Paestum 4938',
    'Paestum 4939',
    'Paestum 4941',
    'Paestum 4943',
    'Paestum 4953',
    'Paestum 49536',
    'Paestum 49537, from C. Arcioni (1976), T. 1',
    'Paestum 49538, from C. Arcioni (1976), G. 1',
    'Paestum 4954',
    'Paestum 4956',
    'Paestum 4957',
    'Paestum 4959',
    'Paestum 4960',
    'Paestum 4961, from C. Gaudo (1957), T. 11',
    'Paestum 4965, from C. Gaudo (1957), T. 11',
    'Paestum 4973',
    'Paestum 4975',
    'Paestum 4978',
    'Paestum 4983',
    'Paestum 4988, from C. Gaudo',
    'Paestum 4990',
    'Paestum 5045',
    'Paestum 5046',
    'Paestum 5047',
    'Paestum 5050',
    'Paestum 5051',
    'Paestum 5052',
    'Paestum 5063',
    'Paestum 5065',
    'Paestum 5066',
    'Paestum 5067',
    'Paestum 5074, C. Arcioni (1953), T. 10',
    'Paestum 5082',
    'Paestum 5104, from Tempa del Prete (1953), T. 1',
    'Paestum 5105, from Tempa del Prete (1953), T. 1',
    'Paestum 5116, Tempa del Prete (1953), T. 2',
    'Paestum 5118, Tempa del Prete (1953), T. 2',
    'Paestum 5134',
    'Paestum 5135',
    'Paestum 5146',
    'Paestum 5166, from Tempa del Prete (1953), T. 6',
    'Paestum 5180',
    'Paestum 5183, from Tempa del Prete (1953), T. 8',
    'Paestum 5184',
    'Paestum 5188',
    'Paestum 5189, from Tempa del Prete (1953), T. 10',
    'Paestum 5197',
    'Paestum 5199',
    'Paestum 5200',
    'Paestum 5205',
    'Paestum 5210',
    'Paestum 5213',
    'Paestum 5214',
    'Paestum 5224, from Tempa del Prete (1953), T. 13',
    'Paestum 5225, Tempa del Prete (1953), T. 13',
    'Paestum 5226',
    'Paestum 5228, from Tempa del Prete (1953), T. 13',
    'Paestum 5240, from Tempa del Prete (1953), T. 15',
    'Paestum 5244',
    'Paestum 5245, from Tempa del Prete (1953), T. 15',
    'Paestum 5246',
    'Paestum 5247',
    'Paestum 5251',
    'Paestum 5272',
    'Paestum 5323, from Tempa del Prete (1953), sporadic',
    'Paestum 5324',
    'Paestum 5335, from C. Andriuolo (1954), sporadic',
    'Paestum 5336, from Tempa del Prete (1953), sporadic',
    'Paestum 5358',
    'Paestum 5368',
    'Paestum 5392',
    'Paestum 5398 (1954), T. 2',
    'Paestum 5401',
    'Paestum 5402',
    'Paestum 5422',
    'Paestum 5423',
    'Paestum 5425',
    'Paestum 5426',
    'Paestum 5429',
    'Paestum 5433',
    'Paestum 5434',
    'Paestum 5435',
    'Paestum 5436',
    'Paestum 5437',
    'Paestum 5438',
    'Paestum 5441',
    'Paestum 5444, from C. Andriuolo (1954)',
    'Paestum 5445',
    'Paestum 5447',
    'Paestum 5459',
    'Paestum 5460',
    'Paestum 5471, from C. Andriuolo (1954), T. 17',
    'Paestum 5472',
    'Paestum 5473, from C. Andriuolo (1954), T. 18',
    'Paestum 5474',
    'Paestum 5475',
    'Paestum 5476',
    'Paestum 5488',
    'Paestum 5511',
    'Paestum 5513',
    'Paestum 5515, C. Andriuolo (1954), T. 28',
    'Paestum 5519',
    'Paestum 5528',
    'Paestum 5534',
    'Paestum 5535',
    'Paestum 5540',
    'Paestum 5542, from C. Andriuolo',
    'Paestum 5548',
    'Paestum 5554',
    'Paestum 5561',
    'Paestum 5562, from C. Andriuolo',
    'Paestum 5565',
    'Paestum 5577, from C. Andriuolo (1954), T. 47',
    'Paestum 5578',
    'Paestum 5602',
    'Paestum 5639',
    'Paestum 5656',
    'Paestum 5657',
    'Paestum 5658',
    'Paestum 5667',
    'Paestum 5668',
    'Paestum 5670',
    'Paestum 5678',
    'Paestum 5680',
    'Paestum 5688',
    'Paestum 5692',
    'Paestum 5695',
    'Paestum 5696',
    'Paestum 5697, from C. Andriuolo-Laghetto (1955), T. 30',
    'Paestum 5700',
    'Paestum 5702',
    'Paestum 5705',
    'Paestum 5710, from C. Andriuolo (1955), T. 34',
    'Paestum 5774',
    'Paestum 5780, from C. Andriuolo-Langhetto',
    'Paestum 5787, from C. Andriuolo (1955), T. 80',
    'Paestum 58 I',
    'Paestum 59 II',
    'Paestum 5954',
    'Paestum 5955',
    'Paestum 5973',
    'Paestum 5974',
    'Paestum 5981',
    'Paestum 59s',
    'Paestum 60 II',
    'Paestum 60/66',
    'Paestum 6002, C. Andriuolo-Laghetto (1955), T. 19',
    'Paestum 6013',
    'Paestum 6014',
    'Paestum 6024',
    'Paestum 6028',
    'Paestum 6029',
    'Paestum 6043',
    'Paestum 6104',
    'Paestum 6105',
    'Paestum 6109, from C. Andriuolo-Laghetto (1955), T. 64',
    'Paestum 6113',
    'Paestum 6120, from C. Andriuolo-Laghetto (1955)',
    'Paestum 6131',
    'Paestum 6133',
    'Paestum 6143, from C. Andriuolo-Laghetto (1955), T. 67',
    'Paestum 6161',
    'Paestum 6192',
    'Paestum 6193',
    'Paestum 6236',
    'Paestum 6237',
    'Paestum 6238',
    'Paestum 6242, from C. Andriuolo-Langhetto',
    'Paestum 6249, from C. Laghetto (1956), T. 3',
    'Paestum 6256',
    'Paestum 6257',
    'Paestum 6258',
    'Paestum 6259',
    'Paestum 6369, C. Laghetto (1956), T. 59',
    'Paestum 6381',
    'Paestum 64 III',
    'Paestum 6455',
    'Paestum 6487',
    'Paestum 65 I',
    'Paestum 65 IV',
    'Paestum 6509',
    'Paestum 6510',
    'Paestum 6541',
    'Paestum 6546',
    'Paestum 6550',
    'Paestum 6551',
    'Paestum 6557',
    'Paestum 6558',
    'Paestum 6561',
    'Paestum 6562',
    'Paestum 6591',
    'Paestum 6592',
    'Paestum 6613 A, C. Andriuolo-Laghetto (1954) sporadic',
    'Paestum 6613B (lid only)',
    'Paestum 6620, from C. Andriuolo-Laghetto (1854), sporadic',
    'Paestum 6623',
    'Paestum 6659',
    'Paestum 70 II + 65 IV + 72s',
    'Paestum 71 II',
    'Paestum 7140',
    'Paestum 7150',
    'Paestum 7178',
    'Paestum 7424, from Agropoli (1967), T. 1',
    'Paestum 7425',
    'Paestum 7428',
    'Paestum 7430, from Muoio d\'Agropoli (1967), T. 1',
    'Paestum 7435, from Agropoli, T. 4',
    'Paestum 7439',
    'Paestum 7446',
    'Paestum 7448',
    'Paestum 7449',
    'Paestum 7457, from Agropoli',
    'Paestum 7464',
    'Paestum 777, from Spinazzo',
    'Paestum E 54/65',
    'Paestum E 65 II',
    'Paestum E 65 III',
    'Paestum from C. Spina-Gaudo (1979)',
    'Paestum H U / 2 0',
    'Paestum H U / 3 7, from the area of the Heraion.',
    'Paestum H U / 5, from the Heraion',
    'Paestum H U 2/1087 (frr.)',
    'Paestum HU/251',
    'Paestum HU/32',
    'Paestum HU/34',
    'Paestum HU/37',
    'Paestum HU/37, from the Heraion, Loc. IV',
    'Paestum HUZ/105B and 106S',
    'Paestum IV/304, from the Heraion, Loc. IV',
    'Paestum IV/451',
    'Paestum IV/452',
    'Paestum IV/462',
    'Paestum, 3553 BA',
    'Paestum, Badolato (Velia)',
    'Paestum, C. Andriuolo (1970), sporadic',
    'Paestum, C. Andriuolo (1971), T. 1',
    'Paestum, C. Andriuolo-Laghetto (1954), sporadic',
    'Paestum, C. Spina Gaudo (7.11.73)',
    'Paestum, C. Spina-Gaudo (1979), T. 64',
    'Paestum, C. Spina-Gaudo (1979), T. 76',
    'Paestum, Foce del Sele',
    'Paestum, from Agropoli (1967), T. 1',
    'Paestum, from area of the Heraion, north of Loc. IV',
    'Paestum, from C. Andriuolo (1957), sporadic',
    'Paestum, from C. Andriuolo (1970), sporadic',
    'Paestum, from C. Andriuolo (1982), T. 680',
    'Paestum, from C. Andriuolo-Laghetto (1955)',
    'Paestum, from C. Arcioni (1978), T. 550',
    'Paestum, from C. Spina-Gaudo (1977), T. 1',
    'Paestum, from C. Spina-Gaudo (1979), T. 55',
    'Paestum, from C. Spina-Gaudo (1979), T. 67',
    'Paestum, from C. Spina-Gaudo (1979), T. 70',
    'Paestum, from C. Spina-Gaudo (1979), T. 74',
    'Paestum, from C. Spina-Gaudo (1979), T. 75',
    'Paestum, from C. Spina-Gaudo (1979), T. 75',
    'Paestum, from C. Spina-Gaudo (1979), T. 76',
    'Paestum, from C. Spina-Gaudo (1979), T. 80',
    'Paestum, from C. Spina-Gaudo (30.8.1975)',
    'Paestum, from Oliveto Citra (1951), T. 2',
    'Paestum, from Pontecagnano (sporadic)',
    'Paestum, from Pontecagnano, T. 18 (6.7.1960)',
    'Paestum, from Roccadaspide Tempalta',
    'Paestum, from Roccadaspide Tempalta (1984)',
    'Paestum, from Roccadaspide Tempalta (1984), T. 1',
    'Paestum, from S. Venera',
    'Paestum, from S. Venera (1976), T. 80',
    'Paestum, from S. Venera (1978), T. 118',
    'Paestum, from S. Venera (31.5.1976), Lato E, trench IV.',
    'Paestum, from S. Venera (sanctuary, sporadic)',
    'Paestum, from the area of the Heraion, Loc. IV',
    'Paestum, from the Heraion, Loc. IV',
    'Paestum, from the Heraion, north of Loc. IV. level 8',
    'Paestum, Heraion, Loc. IV',
    'Paestum, Heraion, Loc. IV',
    'Paestum, Heraion, Loc. IV, level 8',
    'Paestum, Heraion, North of Loc. IV',
    'Paestum, HU/32',
    'Paestum, Loc. IV',
    'Paestum, S. Venera (1976), T. 80',
    'Paestum, S. Venera V S 1549',
    'Paestum, S. Venera V S 2504',
    'Paestum, S. Venera V S 2625',
    'Paestum, V S 2647',
    'Palermo',
    'Palermo 2198 (old no. 3480)',
    'Palermo 2206',
    'Palermo 2260 (old no. 1098)',
    'Palermo 5532',
    'Palermo, Bank of Sicily—Mormino coll. 385, from Selinunte',
    'Palermo, old no. 1005',
    'Paris Market, Galerie Antonovich 1151',
    'Paris Market, Galerie Mythes et Legendes, Sale Cat. 14 (1981), no. 2951',
    'Paris Market, Samarcande',
    'Paris, Cab. Med. 962',
    'Paris, Cab. Med. 992',
    'Perugia, private coll.',
    'Philadelphia L. 29-46',
    'Plymouth, City Museum',
    'Pontecagnano',
    'Pontecagnano',
    'Pontecagnano 10377',
    'Pontecagnano 16306',
    'Pontecagnano 16315, from T. 909',
    'Pontecagnano 16721, from T. 949bis',
    'Pontecagnano 26224',
    'Pontecagnano 26226',
    'Pontecagnano 26227',
    'Pontecagnano 26228',
    'Pontecagnano 26230',
    'Pontecagnano 26231',
    'Pontecagnano 26241',
    'Pontecagnano 26245',
    'Pontecagnano 26319',
    'Pontecagnano 26323',
    'Pontecagnano 26325, from T. 450',
    'Pontecagnano 26412',
    'Pontecagnano 26414, from T. 794',
    'Pontecagnano 26416',
    'Pontecagnano 36113',
    'Pontecagnano 36115',
    'Pontecagnano 36116',
    'Pontecagnano 36117',
    'Pontecagnano 36118',
    'Pontecagnano 36145, from T. 1182',
    'Pontecagnano 36159, from T. 1182',
    'Pontecagnano 36186',
    'Pontecagnano 36402',
    'Pontecagnano 36420, from T. 1183',
    'Pontecagnano 36475, from T. 901',
    'Pontecagnano 36476',
    'Pontecagnano 36477',
    'Pontecagnano 36503',
    'Pontecagnano 36525, from T. 1255',
    'Pontecagnano 36901',
    'Pontecagnano 47805',
    'Pontecagnano, from Pontecagnano',
    'Pontecagnano, from Pontecagnano',
    'Pontecagnano, from T. 3711',
    'Pontecagnano, from T. 3711, no. 12',
    'Pontecagnano, from T. 3711, no. 28',
    'Pontecagnano, from T. 3711, no. 31',
    'Pontecagnano, from T. 4212',
    'Pontecagnano, from T. 569',
    'Pontecagnano, Salerno',
    'Port Sunlight 5043',
    'Port Sunlight 5048/9',
    'Poughkeepsie (N.Y.), Vassar College 48.235',
    'Princeton 79.3',
    'Princeton, Art Museum 37.255',
    'Private collection',
    'Purchase (N.Y.), Neuberger Museum 71.1.15, ex Nelson Rockefeller coll.',
    'Ragusa, Museo Archeologico (ex La Rocca coll.)',
    'Ragusa, private coll.',
    'Reading 50.5.2',
    'Reading 51.7.11',
    'Reading, University 22.iii.23 (lid only)',
    'Reggio Cal. S4799',
    'Reggio Cal., from Locri (fr.)',
    'Rennes D 863.1.27',
    'Rennes D 895.1.67',
    'Rheinbach, C. Koch coll.',
    'Rheinbach, E. Koch coll.',
    'Rheinbach, Koch coll.',
    'Richmond (Virginia)',
    'Richmond (Virginia) 81.72',
    'Rio de Janeiro 1488',
    'Rio de Janeiro 1529',
    'Roccadaspide Tempalta',
    'Rome Market',
    'Rome Market',
    'Rome Market, Benedetti',
    'Rome, Museo Pigorini 93639, from Grotta di Pertosa (Salerno)',
    'Rome, private coll.',
    'Rome, private coll.',
    'Rome, Villa Giulia 50279',
    'Rome, Villa Giulia 50658',
    'Rome, Villa Giulia, ex Castellani coll.',
    'Rothenbach-Pegnitz',
    'Royal-Athena Galleries HZJ 01',
    'Ruvo, Jatta coll. 1694',
    'S. Agata 140',
    'S. Agata 141',
    'S. Agata 142',
    'S. Agata 145',
    'S. Agata 178',
    'S. Agata 184',
    'S. Agata 187',
    'S. Agata 196',
    'S. Agata 199',
    'S. Agata 200',
    'S. Agata 205',
    'S. Agata 66',
    'S. Agata 76',
    'S. Agata 98',
    'S. Francisco, State University, De Bellis coll. 90',
    'Salerno',
    'Salerno',
    'Salerno (Sopr. Arch.), from Montesarchio, T. 891',
    'Salerno (Soprintendenza)',
    'Salerno (Soprintendenza) V D 38-39',
    'Salerno (Soprintendenza), from Montesarchio, T. 1014',
    'Salerno (Soprintendenza), from Montesarchio, T. 1713',
    'Salerno Mus. Prov., Pc 1812, from Pontecagnano',
    'Salerno V D 68',
    'Salerno V D 72',
    'Salerno V D 77',
    'Salerno, Castellabate',
    'Salerno, from Buccino',
    'Salerno, from Oliveto Citra',
    'Salerno, M u s . Prov., from Altavilla',
    'Salerno, M u s . Prov., from Pontecagnano',
    'Salerno, Mus. Prov',
    'Salerno, Mus. Prov.',
    'Salerno, Mus. Prov.',
    'Salerno, Mus. Prov. 1319',
    'Salerno, Mus. Prov. 1335',
    'Salerno, Mus. Prov. 1448',
    'Salerno, Mus. Prov. 1450',
    'Salerno, Mus. Prov. 1450',
    'Salerno, Mus. Prov. 1451',
    'Salerno, Mus. Prov. 1546',
    'Salerno, Mus. Prov. 1552',
    'Salerno, Mus. Prov. 7/1 S.A.O.',
    'Salerno, Mus. Prov. Pc 1317, from Pontecagnano, T. 5',
    'Salerno, Mus. Prov. PC 1322, from Pontecagnano',
    'Salerno, Mus. Prov., from Altavilla',
    'Salerno, Mus. Prov., from Oliveto Citra',
    'Salerno, Mus. Prov., from Pontecagnano',
    'Salerno, Mus. Prov., Pc 1813, from Pontecagnano',
    'Salerno, Museo Prov., from Pontecagnano',
    'Salerno, Sopr. Arch. 38127',
    'Salerno, Sopr. Arch. 38128',
    'Salerno, Sopr. Arch. 38132, from Avella (1981), T. 62 bis',
    'Salerno, Soprintendenza',
    'Salerno, Soprintendenza 28499',
    'Salerno, Soprintendenza V D 58',
    'Salerno, Soprintendenza, from Pontecagnano',
    'Salerno, Soprintendenza, from Pontecagnano (frr.)',
    'SAMA 86-10 P (2)',
    'San Antonio (Texas), Gilbert M. Denman Jr. coll. 270',
    'San Antonio (Texas), Gilbert M. Denman Jr. coll. 318',
    'San Antonio (Texas), Gilbert M. Denman, Jr. coll.',
    'San Antonio 275',
    'San Antonio S A M A 86-32 P(2) (ex Denman coll. 346)',
    'San Francisco, M.H. de Young Museum 225.24865',
    'San Francisco, Mr. Rainer Kreisel',
    'San Francisco, private coll. (Dr. and Mrs. Oremland), ex New York Market, Sotheby, Sale Cat. 24 Nov. 1986, no. 75 (ill.)',
    'San Simeon 5426 (SSW 9847)',
    'Santa Monica, private coll. (ex I.A.C. 4364)',
    'Scottsdale (Arizona), L. M . Cutler coll.',
    'Siena, Chigi-Zondadori coll. 269',
    'St. David\'s (Pa.), Mrs. John F. Arndt',
    'St. Petersburg (Florida), Lemonopoulos coll. 72',
    'St. Petersburg (Florida), Lemonopoulos coll. 75',
    'Stg. 411',
    'Stockholm N.M. 82',
    'Stockholm, C. E. Gait',
    'Stockport, Municipal Museum L.206.77',
    'Stuttgart 4.280 (KAS 187)',
    'Swiss private coll.',
    'Sydney 47.04',
    'Sydney 48.04',
    'Sydney 48.06',
    'Sydney 48-05',
    'Sydney 49.09',
    'Sydney 49.10',
    'Sydney 4901',
    'Sydney W 5 (42.02) (ex Hope 270)',
    'Syracuse 14625',
    'Syracuse 29966',
    'Syracuse 36319',
    'Syracuse 36332',
    'Syracuse 36334',
    'Syracuse 47102',
    'Syracuse 51282',
    'Tampa (Florida), W . K. Zewadski coll.',
    'Tampa (Florida), Zewadski coll. (ex N. German private coll.)',
    'Taranto 56246',
    'Taranto, Ragusa coll. 64',
    'Tatefield Hall (near Harrogate), Kent coll. 379',
    'Tatefield Hall, Kent coll.',
    'Tatefield Hall, Kent coll. 702',
    'The Hague, Schneider-Herrmann coll. 136 (inv. 19)',
    'Thonon, Numifrance',
    'Tirana, Arch. Mus., inv. 68',
    'Tischbein 134',
    'Tischbein I 40',
    'Tischbein I 41',
    'Tischbein I 44',
    'Tischbein II 52',
    'Tokyo Market, Sotheby-Mitsukoshi',
    'Tokyo Market, Sotheby\'s at Mitsukoshi, Sale Cat. 1-2 Oct. 1969, no. 50',
    'Tokyo, private coll. (ex N e w York and Tokyo Markets)',
    'Toledo 77.30',
    'Toulouse 26.340',
    'Toulouse 26.426',
    'Trapani 5255',
    'Trieste 1816',
    'Turin 4585',
    'Turin 4596',
    'Turin 4597',
    'Turin 4696',
    'Turin 4697',
    'Turin 4700',
    'Turin 4703',
    'Turin 4729',
    'Turin, private coll.',
    'Udine 1538',
    'Urbana-Champaign, Univ. of Illinois, World Heritage Museum 22.1.73',
    'Various',
    'Vatican AD 1 (inv. 17370)',
    'Vatican U 15',
    'Vatican U 16 (inv. 17964)',
    'Vatican U 17 (inv. 17965)',
    'Vatican U 18 (inv. 17966)',
    'Vatican U 20 (inv. 17968)',
    'Vatican U 21 (inv. 17969)',
    'Vatican U 22',
    'Vatican U 24',
    'Vatican U 25 (inv. 17973)',
    'Vatican U 52 (inv. 18000)',
    'Vatican U23',
    'Vatican, Astarita 50',
    'Vienna',
    'Vienna 103',
    'Vienna 1133',
    'Vienna 206',
    'Vienna 287',
    'Vienna 2949',
    'Vienna 413',
    'Vienna 418',
    'Vienna 438',
    'Vienna 478',
    'Vienna 527',
    'Vienna 581',
    'Vienna 609',
    'Vienna 622',
    'Vienna 724',
    'Vienna 932',
    'Vienna 964',
    'Vienna 986',
    'Voronezh (ex Dorpat 109)',
    'Warsaw 138062 (ex Majewski Museum 16283)',
    'Warsaw 147160',
    'Warsaw 147269',
    'Warsaw 147300',
    'West Berlin F 3296',
    'Westphalia, D.J. coll. 48',
    'Westphalia, D.J. coll. 49',
    'Westphalian private coll.',
    'Woburn Abbey',
    'Woburn Abbey',
    'Wurzburg H 5739',
    'Wurzburg H 5771',
    'Zurich Market, Arete',
    'Zurich Market, Arete 3013',
    'Zurich Market, Bukowski, Sale Cat. 8 Dec. 1983, no. 135 (ill.)',
    'Zurich Univ. 3946',
    'Zurich, Univ. 3948',
    'Zurich, University 3934',
]