import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedInventory } from '../../Redux/actions';
import Header from '../../Components/HomeScreen/Header';

// Import inventory data
import { inventory } from '../../Data/Inventory';

// Component for displaying inventory terms
function InventoryTerms() {
    // Redux hooks for dispatch and selector
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Correct path to selectedInventory in Redux store
    const selectedInventory = useSelector(state => state.search.selectedInventory);

    // Effect hook to log selected inventory changes
    useEffect(() => {
        console.log(selectedInventory);
    }, [selectedInventory]); // Include selectedInventory in the dependency array to track changes

    // Function to handle inventory click
    function handleInventoryClick(inventory) {
        // Dispatch action to set selected inventory
        dispatch(setSelectedInventory(inventory));
        // Navigate to traditional search page
        navigate('/traditional-search');
    }
    const handleBack = (event) => {
        event.preventDefault();
        navigate(-1);
      };

    // Render the component
    return (
        <div className="app">
            <Header />
            <div className="app__container">
                <div className="background--black-full">
                    <div className="grid-container">
                        <div className="white-text">
                            <div className="container__link navigation-section__link" onClick={handleBack}>
                                <i className="container__link-icon fa-solid fa-circle-arrow-left" style={{marginTop:'30px'}}></i>
                                Return to previous page
                            </div>
                            <div style={{marginLeft:'10px', width:'80%'}}>
                            <h1 style={{margin:'10px 0 30px 0'}}>Inventory Terms</h1>
                            {/* Map through inventory array to display buttons */}
                            {inventory.map((inventoryTerm, index) => (
                                <button key={index} onClick={() => handleInventoryClick(inventoryTerm)}>
                                    {inventoryTerm}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default InventoryTerms;
