// BasicSearchButton.js
import React, { useState } from 'react';
import './BasicSearchButton.css';

const BasicSearchButton = ({ buttonText, buttonUrl }) => {
  const [showGuide, setShowGuide] = useState(false);

  const handleGuideClick = () => {
    setShowGuide(true);
  };

  const handleCloseGuide = () => {
    setShowGuide(false);
  };

  return (
    <div>
      <div className="basicSearchButtonHolder">
        <div className="basicSearchButton" onClick={() => window.location = buttonUrl}>
          {buttonText}
        </div>
        <div className="basicSearchButton" style={{ width: '20px', paddingLeft: '5px', marginLeft: '10px' }} onClick={handleGuideClick}>
          <img className='information-icon-image' src='https://cdn-icons-png.flaticon.com/128/151/151776.png' alt="Information" />
        </div>
      </div>
      
      {showGuide && (
        <div className="user-guide-container" style={{
          position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          backgroundColor: 'white', padding: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', zIndex: 1000,
          width: '80%', maxWidth: '600px', maxHeight: '60%', display: 'flex', flexDirection: 'column'
        }}>
          <div style={{ marginBottom: '10px', borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
            <h2 className="h2" style={{ fontWeight: 'bold', margin: 0, lineHeight: '30px', letterSpacing: '0.8px', color: 'rgb(0, 123, 255)' }}>User Guide for Using the Basic Search Functionality to Find Artifacts</h2>
          </div>
          <div style={{ overflowY: 'auto', flexGrow: 1 }}>
            <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
              <h3 className="h3" style={{ fontWeight: 'bold' }}>Step 1: Select the Basic Search Tool</h3>
              <p className='p'>On the "SEARCH" page, you will see various search options. Select "Basic Search Tool" to access the basic search functionality.</p>
              
              <h3 className="h3" style={{ fontWeight: 'bold' }}>Step 2: Start Your Search</h3>
              <p className='p'>On the "Basic Search Tool" interface, you can start your search using different criteria such as "Shape", "Inventory", and "Provenience". Choose the search criterion you want to use.</p>
              
              <h3 className="h3" style={{ fontWeight: 'bold' }}>Step 3: Search by Shape</h3>
              <p className='p'>If you select "Shape", the interface will display a list of artifact shapes categorized alphabetically. Select a shape from the list to view the corresponding search results.</p>
              
              <h3 className="h3" style={{ fontWeight: 'bold' }}>Step 4: View Search Results</h3>
              <p className='p'>The search results will be displayed as a list of artifacts matching your chosen criterion. Each item in the list will show an image (if available) and basic information about the artifact.</p>
              
              <h3 className="h3" style={{ fontWeight: 'bold' }}>Step 5: View Artifact Details</h3>
              <p className='p'>Click on an item in the search results to view detailed information about that artifact. On the details page, you can see additional information such as descriptions, history, and detailed images (if available).</p>
              
              <h3 className="h3" style={{ fontWeight: 'bold' }}>Step 6: Refine Your Search</h3>
              <p className='p'>After viewing the details, you can refine your search by combining multiple criteria to narrow down the search scope. Use the "Start a new research" option to add more filters to your search and combine them for a more precise result.</p>
            </div>
          </div>
          <button onClick={handleCloseGuide} style={{
            display: 'block', margin: '10px auto 0', padding: '10px 20px', backgroundColor: '#007BFF', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer'
          }}>Close</button>
        </div>
      )}
      
      {showGuide && <div style={{
        position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999
      }} onClick={handleCloseGuide} />}
    </div>
  );
}

export default BasicSearchButton;
